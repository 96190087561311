const homenav = [{
    id: 1,
    name: '首页',
    topath: 'home',
    numIndex: '1',
  },
  {
    id: 2,
    name: '教研圈',
    topath: 'circle',
    numIndex: '2',
  },
  {
    id: 3,
    name: '课程案例',
    topath: 'course',
    numIndex: '3',
  },
  {
    id: 4,
    name: '资源资料',
    topath: 'resources',
    numIndex: '4',
  },
  {
    id: 5,
    name: '通知公告',
    topath: 'activity',
    numIndex: '5',
  },
  {
    id: 6,
    name: '特色项目',
    topath: 'square',
    numIndex: '6',
  },
  {
    id: 7,
    name: '成果推广',
    topath: 'diffusion',
    numIndex: '7',
  },
  {
    id: 8,
    name: '党建品牌',
    topath: 'brand',
    numIndex: '8',
  },
  {
    id: 9,
    name: '个人中心',
    topath: 'userinfo',
    numIndex: '9',
  },
]
export {
  homenav
}